<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          clearable
          v-model="replaceName"
          placeholder="任务名称"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>

      <div class="condition-item">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          style="width: 180px"
          v-model="searchCreateDate"
          type="date"
          placeholder="创建时间"
          autocomplete="off"
          size="small"
          clearable
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="searchCreateDateEnd"
          type="date"
          placeholder="选择创建时间"
          autocomplete="off"
          size="small"
          style="width: 180px"
          clearable
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearchAll"
          size="small"
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleNew"
          size="small"
        >
          新建替换任务
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="replaceMgtAll"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="replaceName" label="任务名称"> </el-table-column>
        <el-table-column
          prop="createTime"
          :formatter="columnDateFormat"
          label="创建时间"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="任务人数"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="photographer"
          label="摄影师"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column label="学生明细" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              class="d-btn"
              type="text"
              @click="viewdetails(scope.row.id)"
              >查看学生明细</el-button
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" align="right" width="300">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="progressButton"
              @click="handleProgress(scope.row.id)"
            >
              查看处理进度
            </el-button>
            <el-button
              type="text"
              v-if="downloadButton"
              @click="handleDownload(scope.row.id)"
            >
              批量下载照片
            </el-button>
            <el-button
              type="text"
              v-if="replaceButton"
              @click="handleReplace(scope.row.id)"
            >
              上传替换照片
            </el-button>
            <el-button
              type="text"
              v-if="ConfirmationButton"
              @click="uploadConfirmation(scope.row.id)"
            >
              上传结果确认
            </el-button>
            <el-button
              type="text"
              v-if="rFailedButton"
              @click="replacementFailed(scope.row.id)"
            >
              查看替换失败学生
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div style="display: flex; justify-content: right; padding-top: 10px">
      <el-pagination
        @size-change="handleSizeChangeAll"
        @current-change="handleCurrentChangeAll"
        :current-page="currentPageAll"
        style="display: flex; justify-content: center; flex-direction: row"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSizeAll"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalAll"
      >
      </el-pagination>
    </div>

    <!-- 选择散拍列表弹窗 -->
    <div>
      <el-dialog
        title="选择订单"
        :visible.sync="dialogVisible"
        width="1200px"
        class="ERER"
      >
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item">
            <label class="fn-14">订单号</label>
            <el-input
              clearable
              v-model="searchOrderNo"
              placeholder="请输入订单号"
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">采集时间</label>
            <el-date-picker
              v-model="searchCreateTime"
              type="date"
              placeholder="选择开始时间"
              autocomplete="off"
              size="small"
              style="width: 160px"
            >
            </el-date-picker>
            <label class="fn-14" style="padding-left: 10px">-</label>
            <el-date-picker
              v-model="searchCreateTimeEnd"
              type="date"
              placeholder="选择结束时间"
              autocomplete="off"
              size="small"
              style="width: 160px"
            >
            </el-date-picker>
          </div>
          <div class="condition-item">
            <label class="fn-14">姓名</label>
            <el-input
              clearable
              v-model="searchStudentName"
              placeholder="请输入学生姓名"
              autocomplete="off"
              size="small"
              style="width: 150px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">手机号</label>
            <el-input
              clearable
              v-model="searchContactTell"
              placeholder="请输入手机号"
              autocomplete="off"
              size="small"
              style="width: 150px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">身份证号</label>
            <el-input
              clearable
              v-model="searchIdNumber"
              placeholder="请输入身份证号"
              autocomplete="off"
              size="small"
              style="width: 180px"
            ></el-input>
          </div>
          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              style="width: 85px"
              @click="handleSearch"
              >搜索</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            border
            :data="choiceList"
            style="width: 100%; text-align: center"
            height="450"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            v-loading="tableReplaceLoading"
            @selection-change="handleSelect"
          >
            <template #empty>
              <p>
                {{ tableReplaceLoading == true ? "数据加载中" : "暂无数据" }}
              </p>
            </template>
            <el-table-column
              align="center"
              type="selection"
              width="50"
              label="全选"
              fixed
            >
            </el-table-column>
            <el-table-column prop="orderNo" label="订单号" width="280" fixed>
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentName"
              label="姓名"
              width="120"
              fixed
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.studentName, "name")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="contactTell"
              label="手机号"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.contactTell, "ph")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="idNumber"
              label="身份证号"
              width="240"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.idNumber, "Idcard")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolName"
              label="学校"
              width="260"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentCode"
              label="学号"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="major"
              label="专业"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              width="160"
              :formatter="columnDateFormat"
              label="采集时间"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="payType"
              label="支付类型"
              width="100"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.payType === 0"
                  type="success"
                  effect="plain"
                  size="small"
                  >线下支付</el-tag
                >
                <el-tag
                  v-else-if="scope.row.payType === 1"
                  type="success"
                  effect="plain"
                  size="small"
                  >工行E支付
                </el-tag>
                <el-tag
                  v-else-if="scope.row.payType === 2"
                  type="success"
                  effect="plain"
                  size="small"
                  >备用支付
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="status"
              label="状态"
              width="100"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.status === 0"
                  type="warning"
                  effect="plain"
                  size="small"
                  >下单</el-tag
                >
                <el-tag
                  v-else-if="scope.row.status === 1"
                  type="success"
                  effect="plain"
                  size="small"
                  >预付款
                </el-tag>
                <el-tag
                  v-else-if="scope.row.status === 2"
                  type=""
                  effect="plain"
                  size="small"
                  >完成</el-tag
                >
                <el-tag v-else type="danger" effect="plain" size="small"
                  >取消</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div style="display: flex; justify-content: right; padding-top: 10px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            style="display: flex; justify-content: center; flex-direction: row"
            :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleStorage">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 新建替换任务弹窗 -->
    <div>
      <el-dialog
        title="创建替换任务"
        :visible.sync="EstablishVisible"
        width="1200px"
        class="ERER"
      >
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="handleChoiceSp"
              >选择散拍任务</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            border
            :data="establishList"
            style="width: 100%; text-align: center"
            height="450"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            v-loading="tableEstablishLoading"
          >
            <template #empty>
              <p>
                {{ tableEstablishLoading == true ? "数据加载中" : "暂无数据" }}
              </p>
            </template>

            <el-table-column prop="orderNo" label="订单号" width="280" fixed>
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentName"
              label="姓名"
              width="100"
              fixed
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.studentName, "name")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="contactTell"
              label="手机号"
              width="160"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.contactTell, "ph")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="idNumber"
              label="身份证号"
              width="220"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.idNumber, "Idcard")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolName"
              label="学校"
              width="260"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentCode"
              label="学号"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="major"
              label="专业"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              width="160"
              :formatter="columnDateFormat"
              label="采集时间"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="payType"
              label="支付类型"
              width="100"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.payType === 0"
                  type="success"
                  effect="plain"
                  size="small"
                  >线下支付</el-tag
                >
                <el-tag
                  v-else-if="scope.row.payType === 1"
                  type="success"
                  effect="plain"
                  size="small"
                  >工行E支付
                </el-tag>
                <el-tag
                  v-else-if="scope.row.payType === 2"
                  type="success"
                  effect="plain"
                  size="small"
                  >备用支付
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="status"
              label="状态"
              width="100"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.status === 0"
                  type="warning"
                  effect="plain"
                  size="small"
                  >下单</el-tag
                >
                <el-tag
                  v-else-if="scope.row.status === 1"
                  type="success"
                  effect="plain"
                  size="small"
                  >预付款
                </el-tag>
                <el-tag
                  v-else-if="scope.row.status === 2"
                  type=""
                  effect="plain"
                  size="small"
                  >完成</el-tag
                >
                <el-tag v-else type="danger" effect="plain" size="small"
                  >取消</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="180"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="d-btn"
                  type="danger"
                  @click.native.prevent="
                    handleDelete(scope.$index, establishList)
                  "
                  >移除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: center; margin-top: 15px">
          <el-button
            type="primary"
            size="small"
            @click="buildReplace"
            :loading="loadingReplace"
            >创建替换任务</el-button
          >
        </div>
      </el-dialog>
    </div>

    <!-- 查看失败学生信息 -->
    <div>
      <el-dialog
        title="查看失败学生信息"
        :visible.sync="failStuVisible"
        width="1200px"
        class="ERER"
      >
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item">
            <label class="fn-14">订单号</label>
            <el-input
              clearable
              v-model="searchOrderNoFail"
              placeholder="请输入订单号"
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">姓名</label>
            <el-input
              clearable
              v-model="searchStuFail"
              placeholder="请输入学生姓名"
              autocomplete="off"
              size="small"
              style="width: 150px"
            ></el-input>
          </div>

          <div class="condition-item">
            <label class="fn-14">身份证号</label>
            <el-input
              clearable
              v-model="searchStuIdNumberFail"
              placeholder="身份证号"
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="handleFailStu"
              >查询</el-button
            >
            <el-button
              type="success"
              size="small"
              icon="el-icon-s-data"
              @click="exportFailStu"
              >导出替换失败学生</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            border
            :data="failStuList"
            style="width: 100%; text-align: center"
            height="450"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            v-loading="failStuLoading"
          >
            <template #empty>
              <p>
                {{ failStuLoading == true ? "数据加载中" : "暂无数据" }}
              </p>
            </template>

            <el-table-column prop="orderNo" label="订单号" width="280" fixed>
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentName"
              label="姓名"
              width="100"
              fixed
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="contactTell"
              label="手机号"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="idNumber"
              label="身份证号"
              width="220"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolName"
              label="学校"
              width="260"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentCode"
              label="学号"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="major"
              label="专业"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              width="160"
              :formatter="columnDateFormat"
              label="采集时间"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="payType"
              label="支付类型"
              width="100"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.payType === 0"
                  type="success"
                  effect="plain"
                  size="small"
                  >线下支付</el-tag
                >
                <el-tag
                  v-else-if="scope.row.payType === 1"
                  type="success"
                  effect="plain"
                  size="small"
                  >工行E支付
                </el-tag>
                <el-tag
                  v-else-if="scope.row.payType === 2"
                  type="success"
                  effect="plain"
                  size="small"
                  >备用支付
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="reason" width="240" label="失败原因">
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div
            style="display: flex; justify-content: center; padding-top: 10px"
          >
            <el-pagination
              @size-change="handleSizeChangeFail"
              @current-change="handleCurrentChangeFail"
              :current-page="currentPageFail"
              style="
                display: flex;
                justify-content: center;
                flex-direction: row;
              "
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              :page-size="pageSizeFail"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalFail"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 处理进度 -->
    <div>
      <el-dialog
        title="处理进度"
        :visible.sync="progressVisible"
        width="900px"
        class="ERER"
      >
        <!-- 弹窗列表盒子 -->
        <div>
          <el-table
            border
            :data="progressListInfo"
            ref="multipleTable"
            style="width: 100%; text-align: center"
            height="400"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
          >
            <template #empty>
              <p>{{ progressLoading == true ? "数据加载中" : "暂无数据" }}</p>
            </template>

            <el-table-column
              align="center"
              prop="replaceReason"
              label="序号"
              width="80"
              fixed
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="photographer"
              label="摄影师"
              width="120"
            >
            </el-table-column>

            <el-table-column
              align="center"
              prop="createTime"
              :formatter="columnDateFormat"
              label="处理时间"
              width="240"
            >
            </el-table-column>
            <el-table-column align="center" prop="remark" label="处理结果">
            </el-table-column>
          </el-table>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="progressVisible = false">返 回</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 上传替换照片 -->
    <div>
      <el-dialog
        title="上传替换照片"
        :visible.sync="ReplacephotosVisible"
        width="450px"
        class="ERER"
      >
        <div
          style="
            max-height: 500px;
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
          "
          v-loading="FileUpdateLoading"
          element-loading-text="文件正在上传中.."
          element-loading-spinner="el-icon-loading"
        >
          <el-form
            ref="ReplacephotosForm"
            :model="ReplacephotosForm"
            label-width="120px"
            :rules="ReplacephotosRules"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="替换原因:" prop="ReplacephotosReason">
                  <el-radio-group
                    v-model="ReplacephotosForm.ReplacephotosReason"
                  >
                    <el-radio :label="1">图片处理</el-radio>
                    <el-radio :label="0">增值服务</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="人脸识别:" prop="ReplacephotosFace">
                  <el-radio-group v-model="ReplacephotosForm.ReplacephotosFace">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">不开启</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="上传替换文件:" prop="appointmentType">
                  <el-upload
                    class="upload-demo"
                    ref="uploadImport"
                    :multiple="false"
                    action=""
                    :limit="1"
                    :on-change="handleUploadChange"
                    :file-list="[]"
                    accept=".zip,.rar"
                    :auto-upload="false"
                    :show-file-list="false"
                  >
                    <el-button slot="trigger" size="small" type="primary"
                      >{{
                        RepUpdateLoading == true ? "文件已导入完成" : "选取文件"
                      }}
                    </el-button>
                  </el-upload></el-form-item
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="handleCancel">取 消</el-button>
          <el-button
            type="primary"
            @click="handleConfirm"
            :disabled="FileUpdateLoading == true"
          >
            确 定
          </el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 查看明细 -->
    <div>
      <el-dialog
        title="查看学生明细"
        :visible.sync="detailedVisible"
        width="1200px"
        class="ERER"
      >
        <!-- 弹窗列表盒子 -->

        <div>
          <!-- 搜索部分 -->
          <div style="display: flex; flex-wrap: wrap">
            <div class="condition-item">
              <label class="fn-14">订单号</label>
              <el-input
                clearable
                v-model="searchOrderNoDled"
                placeholder="请输入订单号"
                autocomplete="off"
                size="small"
              ></el-input>
            </div>
            <div class="condition-item">
              <label class="fn-14">姓名</label>
              <el-input
                clearable
                v-model="searchStuDetailed"
                placeholder="请输入学生姓名"
                autocomplete="off"
                size="small"
              ></el-input>
            </div>
            <div class="condition-item">
              <label class="fn-14">身份证号</label>
              <el-input
                clearable
                v-model="searchStuIdNumber"
                placeholder="身份证号"
                autocomplete="off"
                size="small"
              ></el-input>
            </div>

            <div class="condition-item">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-search"
                @click="handleDetailed"
                >查询</el-button
              >
            </div>
          </div>
          <el-table
            border
            :data="detailedListInfo"
            ref="multipleTable"
            style="width: 100%; text-align: center"
            height="400"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
          >
            <template #empty>
              <p>{{ detailedLoading == true ? "数据加载中" : "暂无数据" }}</p>
            </template>

            <el-table-column prop="orderNo" label="订单号" width="260" fixed>
            </el-table-column>
            <el-table-column
              align="center"
              prop="name"
              label="姓名"
              width="140"
              fixed
            >
              <template slot-scope="scope">
                <span>{{ execDecrypt(scope.row.name, "name") }}</span></template
              >
            </el-table-column>
            <el-table-column
              width="240"
              align="center"
              prop="idNumber"
              label="身份证号"
            >
              <template slot-scope="scope">
                <span>{{
                  execDecrypt(scope.row.idNumber, "Idcard")
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              width="200"
              prop="studentCode"
              label="学号"
            >
            </el-table-column>

            <el-table-column
              align="center"
              width="160"
              prop="schoolType"
              label="学校类别"
            >
            </el-table-column>
            <el-table-column
              align="center"
              width="160"
              prop="educationType"
              label="学历类别"
            >
            </el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="schoolCode"
              label="学校代码"
            >
            </el-table-column>
            <el-table-column
              align="center"
              width="200"
              prop="schoolName"
              label="学校"
            >
            </el-table-column>
            <el-table-column
              align="center"
              width="180"
              prop="major"
              label="专业"
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div style="display: flex; justify-content: center; padding-top: 10px">
          <el-pagination
            @size-change="handleSizeChangeDled"
            @current-change="handleCurrentChangeDled"
            :current-page="currentPageDled"
            style="display: flex; justify-content: center; flex-direction: row"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="pageSizeDled"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalDled"
          >
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="detailedVisible = false">返 回</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 上传结果复核 -->
    <div>
      <el-dialog
        title="上传结果复核"
        :visible.sync="uploadConVisible"
        width="600px"
        class="ERER"
      >
        <!-- <span style="color: #5c7af3; margin-left: 55px"
          >待确认替换照片{{ imgListCount }}/<span v-if="imgListCount == 0">{{
            imgIndex
          }}</span
          ><span v-else>{{ imgIndex + 1 }}</span
          >张</span
        > -->
        <span style="color: #5c7af3; margin-left: 55px"
          >待确认替换照片<span v-if="imgListCount == 0">{{ imgIndex }}</span
          ><span v-else>{{ imgIndex + 1 }}</span
          >张</span
        >
        <div class="img_box" v-model="detailInfo">
          <!-- 替换前 -->
          <div class="img_style">
            <img :src="pathCollectedPhoto" alt="" class="img" />
            <p>订单原照片</p>
          </div>
          <!-- 替换后 -->
          <div class="img_style">
            <img :src="replaceCollectedPhoto" alt="" class="img" />
            <p>替换后照片</p>
          </div>
        </div>
        <p style="width: 300px; margin: 0 auto; text-align: center">
          订单号:{{ detailInfo.orderNo || "-" }}(第{{
            detailInfo.serialNumber || "0"
          }}组)
        </p>
        <div slot="footer" class="dialog-footer uploadClass">
          <el-button
            @click="upReplacePhotos('not')"
            :disabled="imgListCount == imgIndex"
            >暂不替换</el-button
          >
          <el-button
            type="primary"
            @click="upReplacePhotos('yes')"
            :disabled="imgListCount == imgIndex"
            >确认替换</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<style scoped>
.uploadClass {
  width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.img_box {
  width: 450px;
  height: 280px;
  padding: 20px 0px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  margin: 0 auto;
}
.img_style {
  width: 180px;
  height: 240px;
  text-align: center;
}
.img {
  width: 100%;
  height: 100%;
}
</style>
<script>
import {
  getReplacePhotosSP,
  replacePhotoTaskAdd,
  getQueryTasks,
  getDownloadReplaceSp,
  getQueryinfos,
  getQueryreplaceFail,
  getFailReplace,
  photoDetail,
  checkReplace,
  getCheckProgress,
  minioUpload,
  merge,
  mergeEnd,
} from "@/api/order";
import { exportprocess } from "@/api/student";
import { dateFormat } from "@/utils/date";
import { queryPageButton } from "@/api/permission";
import axios from "axios";
import { getToken } from "@/utils/auth";
import SparkMD5 from "spark-md5";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
const chunkSize = 5 * 1024 * 1024;

export default {
  name: "batchReplaceSpMgt",
  data() {
    const validateReason = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择替换原因"));
      } else {
        callback();
      }
    };
    const validateFace = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否开启人脸识别"));
      } else {
        callback();
      }
    };
    return {
      ReplacephotosRules: {
        ReplacephotosReason: [
          {
            required: true,
            trigger: "blur",
            validator: validateReason,
          },
        ],
        ReplacephotosFace: [
          {
            required: true,
            trigger: "blur",
            validator: validateFace,
          },
        ],
      },
      searchCreateDate: null,
      searchCreateDateEnd: null,
      replaceName: "",

      // 弹窗列表搜素条件
      searchOrderNo: "",
      searchCreateTime: "",
      searchCreateTimeEnd: "",
      searchStudentName: "",
      searchContactTell: "",
      searchIdNumber: "",

      // 失败搜索
      searchOrderNoFail: "",
      searchStuFail: "",
      searchStuIdNumberFail: "",

      // 明细搜索
      searchOrderNoDled: "",
      searchStuDetailed: "",
      searchStuIdNumber: "",
      detailsId: "",
      Failid: "",
      tableSelection: [],
      replaceMgtAll: [],
      replaceMgtList: [],
      establishList: [],
      choiceList: [],
      failStuList: [],
      progressListInfo: [],
      imgList: [],
      detailInfo: {},
      ReplacephotosForm: {
        type: 1,
        ReplacephotosReason: 1,
        ReplacephotosFace: 1,
        file: null,
      },
      detailedListInfo: [],
      uploadId: "",
      imgIndex: 0,
      imgListCount: 0,
      pathCollectedPhoto: "../temp/none_img.jpg",
      replaceCollectedPhoto: "../temp/none_img.jpg",
      // 选择列表
      currentPage: 1,
      pageSize: 20,
      total: 0,
      // 散拍列表
      currentPageAll: 1,
      pageSizeAll: 20,
      totalAll: 0,
      // 失败
      currentPageFail: 1,
      pageSizeFail: 20,
      totalFail: 0,
      // 明细
      currentPageDled: 1,
      pageSizeDled: 20,
      totalDled: 0,

      loading: false,
      tableLoading: false,
      tableReplaceLoading: false,
      tableEstablishLoading: false,
      dialogVisible: false,
      EstablishVisible: false,
      loadingReplace: false,
      failStuVisible: false,
      failStuLoading: false,
      progressVisible: false,
      progressLoading: false,
      ReplacephotosVisible: false,
      ReplacephotosLoading: false,
      RepUpdateLoading: false,
      detailedVisible: false,
      detailedLoading: false,
      uploadConVisible: false,
      FileUpdateLoading: false,
      TexTloading: null,
      //按钮权限
      searchButton: false,
      viewButton: false,
      progressButton: false,
      downloadButton: false,
      replaceButton: false,
      ConfirmationButton: false,
      rFailedButton: false,
    };
  },
  created() {
    this.queryButton();
    this.QueryTasks();
  },
  methods: {
    queryButton() {
      const data = 116;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "upload") {
            this.uploadButton = true;
          }
          if (button.code === "cencel") {
            this.cencelButton = true;
          }
          if (button.code === "progressButton") {
            this.progressButton = true;
          }
          if (button.code === "replaceButton") {
            this.replaceButton = true;
          }
          if (button.code === "ConfirmationButton") {
            this.ConfirmationButton = true;
          }
          if (button.code === "rFailedButton") {
            this.rFailedButton = true;
          }
          if (button.code === "downloadButton") {
            this.downloadButton = true;
          }
        }
      });
    },

    // 选择散拍任务列表
    replaceQuery() {
      this.tableReplaceLoading = true;
      const data = {};
      data.orderNo = this.searchOrderNo;
      data.studentName = this.searchStudentName;
      data.sell = this.searchContactTell;
      data.number = this.searchIdNumber;

      if (this.searchCreateTime !== "") {
        data.beginTime = this.formDateTimeBegin(this.searchCreateTime);
      }
      if (this.searchCreateTimeEnd !== "") {
        data.endTime = this.formDateTimeEnd(this.searchCreateTimeEnd);
      }
      getReplacePhotosSP(this.currentPage, this.pageSize, data).then((resp) => {
        if (resp.code == 0) {
          let datas = resp.data.content;
          this.choiceList = datas;
          this.total = resp.data.totalElements;
        }
        this.tableReplaceLoading = false;
      });
    },

    // 明细列表
    Queryinfos(id) {
      this.detailedLoading = true;
      const data = {
        orderNo: this.searchOrderNoDled,
        studentName: this.searchStuDetailed,
        idNumber: this.searchStuIdNumber,
        taskId: id,
      };
      getQueryinfos(this.currentPageDled, this.pageSizeDled, data).then(
        (resp) => {
          if (resp.code == 0) {
            let datas = resp.data.content;
            this.detailedListInfo = datas;
            this.totalDled = resp.data.totalElements;
          }
          this.detailedLoading = false;
        }
      );
    },

    // 失败列表
    getQueryreplaceFail(id) {
      this.failStuLoading = true;
      const data = {
        orderNo: this.searchOrderNoFail,
        studentName: this.searchStuFail,
        idNumber: this.searchStuIdNumberFail,
        taskId: id,
      };
      getQueryreplaceFail(this.currentPageFail, this.pageSizeFail, data).then(
        (resp) => {
          if (resp.code == 0) {
            let datas = resp.data.content;
            this.failStuList = datas;
            this.totalFail = resp.data.totalElements;
          }
          this.failStuLoading = false;
        }
      );
    },
    // 散拍页面列表
    QueryTasks(id) {
      this.tableLoading = true;
      let data = {
        replaceName: this.replaceName,
        collectType: 1,
        stime: this.formDateTimeBegin(this.searchCreateDate),
        etime: this.formDateTimeEnd(this.searchCreateDateEnd),
      };

      getQueryTasks(this.currentPageAll, this.pageSizeAll, data).then(
        (resp) => {
          if (resp.code == 0) {
            let datas = resp.data.content;
            this.replaceMgtAll = datas;
            this.totalAll = resp.data.totalElements;
          }
          this.tableLoading = false;
        }
      );
    },

    // 查看学生明细
    viewdetails(id) {
      this.detailsId = id;
      this.detailedVisible = true;
      this.Queryinfos(this.detailsId);
    },
    // 查看进度
    handleProgress(id) {
      this.progressListInfo = [];
      this.progressVisible = true;
      this.progressLoading = true;
      getCheckProgress(id).then((resp) => {
        if (resp.code == 0) {
          this.progressListInfo.push(resp.data);
        } else {
          this.$notify.error({
            title: "警告",
            message: resp.message,
          });
        }
        this.progressLoading = false;
      });
    },
    // 批量下载
    handleDownload(id) {
      const taskId = id;
      this.TexTloading = this.$loading({
        lock: true,
        text: "文件打包中....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getDownloadReplaceSp(taskId).then((resp) => {
        if (resp.code == "0") {
          this.taskExportprocess(resp.data.taskNo);
        } else {
          this.$notify.error({
            title: "错误",
            message: "下载失败，打包文件不存在",
          });
          this.TexTloading.close();
        }
      });
    },

    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo = "") {
      let that = this;
      exportprocess(taskNo).then((res) => {
        if (res.data.state == 0) {
          setTimeout(() => {
            that.taskExportprocess(taskNo);
          }, 500);
        } else if (res.data.state == 1) {
          that.$notify({
            title: "成功",
            message: "打包下载成功",
            type: "success",
          });
          if (res.data.msg == "打包成功") {
            this.TexTloading.close();
          }
          setTimeout(() => {
            let urlFile = res.data.url;
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = urlFile;
            let fileName = Date.parse(new Date()) + ".rar";
            link.download = fileName;
            console.log(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
          }, 1000);
          this.queryLIst();
        } else {
          this.$notify({
            title: "警告",
            message: res.data.msg[0],
            type: "warning",
          });
          this.TexTloading.close();
          this.queryLIst();
        }
      });
    },

    // 上传照片
    handleUploadChange(file, fileList) {
      if (file.raw) {
        this.ReplacephotosForm.file = file;
        this.RepUpdateLoading = true;
      }
    },
    // 上传替换
    handleReplace(id) {
      this.uploadId = id;
      this.ReplacephotosVisible = true;
    },
    //上传替换照片取消

    handleCancel() {
      this.RepUpdateLoading = false;
      this.ReplacephotosVisible = false;
    },

    //上传替换照片确认
    async handleConfirm() {
      if (this.RepUpdateLoading != true) {
        this.$notify({
          title: "警告",
          message: "请先上传文件后在进行确认操作",
          type: "warning",
        });
      } else {
        this.FileUpdateLoading = true;
        let dataPar = this.ReplacephotosForm;
        let file = dataPar.file.raw;
        const fileMd5 = await this.getFileMd5(file);
        let i = 0;
        this.PostFile(file, i, fileMd5);
      }
    },
    // 分片上传文件
    PostFile(file, i, fileMd5) {
      let fileName = file.name,
        fileType = file.type,
        fileSize = file.size,
        chunkCount = Math.ceil(fileSize / chunkSize); //总片数
      if (i >= chunkCount) {
        return;
      }
      let start = i * chunkSize;
      let end = start + chunkSize;
      let packet = file.slice(start, end); //将文件进行切片
      console.log(packet);
      let form = new FormData();
      form.append("md5", fileMd5);
      form.append("file", packet); //slice方法用于切出文件的一部分
      form.append("name", fileName);
      form.append("totalSize", fileSize);
      form.append("total", chunkCount); //总片数
      form.append("index", i + 1); //当前是第几片
      minioUpload(form).then((resp) => {
        if (resp.data.status === 20001) {
          /*  表示上一块文件上传成功，继续下一次  */
          form = "";
          i++;
          this.PostFile(file, i, fileMd5);
        } else if (resp.data.status === 50000) {
          form = "";
          /*  失败后，每2秒继续传一次分片文件  */
          setInterval(function () {
            this.PostFile(file, i, fileMd5);
          }, 2000);
        } else if (resp.data.status === 20002) {
          this.merge(chunkCount, fileName, fileMd5, fileSize, fileType);
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        }
      });
    },
    // 合并分片文件
    merge(chunkCount, fileName, fileMd5, fileSize, fileType) {
      let dataPar = this.ReplacephotosForm;
      let postData = {
        shardCount: chunkCount,
        md5: fileMd5,
        fileName: fileName,
        fileType: fileType,
        fileSize: fileSize,
        replaceReason: dataPar.ReplacephotosReason,
        isFace: dataPar.ReplacephotosFace,
        collectType: 1,
        id: this.uploadId,
      };
      merge(postData).then((resp) => {
        this.FileUpdateLoading = false;
        if (resp.code == 0) {
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
          let lastData = {
            fileName: resp.data.fileName,
            replaceReason: postData.replaceReason,
            isFace: postData.isFace,
            collectType: 1,
            id: postData.id,
          };
          mergeEnd(lastData).then((resp) => {
            if (resp.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: resp.message,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: "未知错误",
                type: "warning",
              });
            }
          });
        } else {
          this.$notify({
            title: "警告",
            message: "未知错误",
            type: "warning",
          });
        }
        this.ReplacephotosVisible = false;
      });
    },
    // md5文件转化
    getFileMd5(file, chunkCount) {
      return new Promise((resolve, reject) => {
        let blobSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        let chunks = chunkCount;
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();

        fileReader.onload = function (e) {
          spark.append(e.target.result);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
          } else {
            let md5 = spark.end();
            resolve(md5);
          }
        };
        fileReader.onerror = function (e) {
          reject(e);
        };
        function loadNext() {
          let start = currentChunk * chunkSize;
          let end = start + chunkSize;
          if (end > file.size) {
            end = file.size;
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
      });
    },

    // 上传复核列表
    getFileData(id) {
      photoDetail(id).then((resp) => {
        if (resp.code == 0) {
          let data = resp.data.content;
          if (data.length > 0) {
            this.imgList = data;
            this.imgListCount = resp.data.size;
            this.imgIndex = 0;
            this.getDetailInfo();
          }
        }
        // else {
        //   this.$notify.error({
        //     title: "错误",
        //     message: resp.message,
        //   });
        // }
      });
    },
    getDetailInfo() {
      const json = this.imgList;
      this.detailInfo = json[this.imgIndex] || {};
      if (this.detailInfo && this.detailInfo.id) {
        this.getPhotoFile(
          this.detailInfo.pathCollectedPhoto,
          "pathCollectedPhoto"
        );
        if (this.detailInfo.replaceCollectedPhoto) {
          this.getPhotoFile(
            this.detailInfo.replaceCollectedPhoto,
            "replaceCollectedPhoto"
          );
        }
      } else {
        //error
        this.$notify.error({
          title: "错误",
          message: "照片加载失败，未查询到照片信息",
        });
        this.detailInfo = {};
        (this.pathCollectedPhoto = "../temp/none_img.jpg"),
          (this.replaceCollectedPhoto = "../temp/none_img.jpg"),
          (this.imgIndex = 0);
        return false;
      }
    },
    // 上传复核列表---查询图片
    getPhotoFile(fileid, typeImg) {
      const url =
        "/api/csias/file/download/" +
        fileid +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          // console.info(response);
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            const imgs = window.URL.createObjectURL(imgContent);
            if (typeImg == "pathCollectedPhoto") {
              this.pathCollectedPhoto = imgs;
              this.lodingImg += 1;
            } else if (typeImg == "replaceCollectedPhoto") {
              this.lodingImg += 2;
              this.replaceCollectedPhoto = imgs;
            }
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },

    // 上传结果确认
    uploadConfirmation(id) {
      (this.detailInfo = {}),
        (this.imgIndex = 0),
        (this.imgListCount = 0),
        (this.pathCollectedPhoto = "../temp/none_img.jpg");
      this.replaceCollectedPhoto = "../temp/none_img.jpg";
      let data = id;
      this.getFileData(data);
      this.uploadConVisible = true;
    },
    // 是否替换照片
    upReplacePhotos(mod) {
      let params = {};
      params.id = this.detailInfo.id;
      if (params.id != undefined) {
        if (mod == "not") {
          params.isReplace = 1;
        } else if (mod == "yes") {
          params.isReplace = 2;
        }
        checkReplace(params).then((resp) => {
          if (resp.code == 0) {
            if (this.imgIndex + 1 == this.imgListCount) {
              this.imgListCount = 0;
              this.imgIndex = 0;
              this.detailInfo = {};
              (this.pathCollectedPhoto = "../temp/none_img.jpg"),
                (this.replaceCollectedPhoto = "../temp/none_img.jpg"),
                this.$notify({
                  title: "温馨提示",
                  message: "当前照片替换完毕",
                  type: "warning",
                });
            } else {
              this.$notify({
                title: "温馨提示",
                message: resp.message,
                type: "success",
              });
              this.imgIndex = this.imgIndex + 1;
              this.lodingImg = 0;
              this.getDetailInfo();
            }
          }
        });
      } else {
        this.$notify({
          title: "温馨提示",
          message: "当前暂无照片需要替换",
          type: "warning",
        });
      }

      this.uploadConVisible = true;
    },

    // 查看替换失败
    replacementFailed(id) {
      this.Failid = id;
      this.failStuVisible = true;
      this.getQueryreplaceFail(this.Failid);
    },
    // 新建替换
    handleNew() {
      this.EstablishVisible = true;
      this.establishList = [];
      // this.tableEstablishLoading = true;
    },

    // 移除
    handleDelete(index, rows) {
      rows.splice(index, 1);
    },
    // 散拍列表搜索
    handleSearchAll() {
      this.currentPageAll = 1;
      this.QueryTasks();
    },

    // 选择散拍任务
    handleChoiceSp() {
      this.dialogVisible = true;
      this.choiceList = [];
      this.replaceQuery();
    },
    // 创建替换任务
    buildReplace() {
      this.loadingReplace = true;
      const json = JSON.parse(JSON.stringify(this.establishList));
      let ids = [];
      let postData = {};
      json.map((item) => {
        ids.push(item.orderNo);
      });
      if (!ids.length) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
      } else {
        postData.ids = ids.join(",");
        replacePhotoTaskAdd(postData).then((resp) => {
          if (resp.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: "任务创建成功",
              type: "success",
            });
          } else {
            this.$notify({
              title: "温馨提示",
              message: "任务创建失败,请重新创建",
              type: "warning",
            });
          }
          this.loadingReplace = false;
        });
        this.EstablishVisible = false;
        this.QueryTasks();
      }
    },
    // 散拍弹窗选择
    handleSelect(val) {
      this.tableSelection = val;
    },
    // 散拍弹窗选择确定
    handleStorage() {
      //  console.log(this.tableSelection);
      const tableSelection = JSON.parse(JSON.stringify(this.tableSelection));
      if (tableSelection === null || tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
        return;
      }
      let establishData = [];
      if (this.establishList.length == 0) {
        establishData = tableSelection;
        for (const item of establishData) {
          this.establishList.push(item);
        }
      } else {
        for (const item of tableSelection) {
          const json = this.establishList.filter(
            (o) => o.orderNo == item.orderNo
          );
          if (!json.length) {
            this.establishList.push(item);
          }
        }
      }
      this.dialogVisible = false;
    },
    // 散拍弹窗查询
    handleSearch() {
      debugger
      this.currentPage = 1;
      let data = {};
      let timeInterval = Number(3600 * 1000 * 24 * 7);
      if (this.searchCreateTime || this.searchCreateTimeEnd) {
        if (this.searchCreateTime != "") {
          data.oldBeginTime =
            new Date(this.searchCreateTime).getTime() - timeInterval;
        } else if (this.searchCreateTimeEnd != "") {
          data.oldEndTime =
            new Date(this.searchCreateTimeEnd).getTime() - timeInterval;
        }
      }
      console.log('之后'+data);
      console.log(this.searchCreateTime);
      console.log(this.searchCreateTimeEnd);


      // this.replaceQuery();
    },
    // 失败查询
    handleFailStu() {
      this.currentPageFail = 1;
      this.getQueryreplaceFail(this.Failid);
    },
    // 导出失败学生
    exportFailStu() {
      getFailReplace(this.Failid).then((resp) => {
        if (resp.code == "0") {
          let urlFile = resp.data;
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = urlFile;
          let fileName = Date.parse(new Date()) + ".xlsx";
          link.download = fileName;
          // console.log(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
        } else {
          this.$notify.error({
            title: "错误",
            message: "下载失败，打包文件不存在",
          });
        }
      });
    },
    // 明细查询
    handleDetailed() {
      this.currentPageDled = 1;
      this.Queryinfos(this.detailsId);
    },
    // 选择散拍分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.replaceQuery();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.replaceQuery();
    },
    // 散拍列表分页
    handleSizeChangeAll(val) {
      this.pageSizeAll = val;
      this.QueryTasks();
    },
    handleCurrentChangeAll(val) {
      this.currentPageAll = val;
      this.QueryTasks();
    },
    // 查看替换失败分页
    handleSizeChangeFail(val) {
      this.pageSizeFail = val;
      this.getQueryreplaceFail(this.Failid);
    },
    handleCurrentChangeFail(val) {
      this.currentPageFail = val;
      this.getQueryreplaceFail(this.Failid);
    },
    // 查看明细分页
    handleSizeChangeDled(val) {
      this.pageSizeDled = val;
      this.Queryinfos(this.detailsId);
    },
    handleCurrentChangeDled(val) {
      this.currentPageDled = val;
      this.Queryinfos(this.detailsId);
    },

    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateTimeBegin(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 00:00:00", new Date(dateValue));
      }
    },
    formDateTimeEnd(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 23:59:59", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style></style>
